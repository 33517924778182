<template>
  <div class="video-box">
    <div v-if="file" class="video-field">
      <video controls>
        <source :src="file.link">
        Your browser does not support the video tag.
      </video>
      <div class="video-box__buttons">
        <b-btn
          variant="danger"
          size="xs"
          @click.prevent="deleteFile"
        >
          Delete
        </b-btn>
      </div>
    </div>
    <div v-else-if="isLoading" class="busy-field">
      Loading..
    </div>
    <div
      v-else
      class="upload-field text-xl"
      :class="{ 'upload-field__dragging': isDragging }"
      @click="selectFile"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <i class="fas fa-upload"></i>
      <input
        ref="file"
        type="file"
        :disabled="isLoading"
        @change="uploadFile()"
        accept="video/*"
        class="input-file hidden"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoBox',
  components: {},
  props: {
    upload: null,
    data: Object,
    field: String,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    fieldValue() {
      return this.data[this.field];
    },
  },
  data() {
    return {
      loadingCount: 0,
      file: null,
      isDragging: false,
    };
  },
  methods: {
    fetchFile() {
      if (this.fieldValue === null) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/files/${this.fieldValue}`)
        .then((res) => {
          this.file = res.body.file;
        })
        .catch((err) => {
          alert(`Failed to fetch file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteFile() {
      if (!confirm('Do you really wish to delete this file?')) {
        return;
      }
      this.data[this.field] = null;
      this.file = null;
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      const file = this.$refs.file.files[0];
      if (!file) {
        return;
      }
      if (!file.type.startsWith('video')) {
        this.$toast.error(`${file.name} is not a video!`);
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mtime', file.lastModified / 1000);
      this.$http
        .post('/files')
        .send(formData)
        .then((res) => {
          this.data[this.field] = res.body.file.id;
          this.file = res.body.file;
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.isDragging = false;
      this.uploadFile();
    },
  },
  created() {
    this.fetchFile();
  },
};
</script>

<style lang="scss" scoped>
.video-box {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;

  &:hover {
    cursor: pointer;
  }

  .upload-field:hover, .upload-field__dragging {
    background-color: #efefef;
  }

  .video-field {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }

  .busy-field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
  }

  .upload-field {
    position: relative;;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: -webkit-fill-available;
  }

  &:hover .video-box__buttons {
    display: block;
  }
}

.video-box__buttons {
  display: none;
  position: absolute;
  right: 6px;
  top: 6px;
}
</style>
